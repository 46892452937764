import React from "react";
import Navbar from "./Navbar";

function About() {
    return (
        <div>
            <Navbar />
            <p>About</p>
        </div>
    )
}

export default About;