import React from "react";
import Navbar from "./Navbar";

function Projects() {
    return (
        <div>
            <Navbar />
            <p>Projects</p>
        </div>
    )
}

export default Projects;