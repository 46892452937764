import './App.css';
import Navbar from './pages/Navbar';

function App() {
  return (
    <div>
      <header>
        <Navbar />
        <p>Home</p>
      </header>
      <body>


      </body>
    </div>
  );
}

export default App;
