import React from "react";
import Navbar from "./Navbar";

function Contact() {
    return (
        <div>
            <Navbar />
            <p>Contact</p>
            <p>7865632696</p>
            <p>ljtorrer@gmail.com</p>
        </div>
    )
}

export default Contact;